.dataTable {
  padding: 2em 0;
  width: calc(100% - 20px);
  margin: 0 10px;
  @media (max-width: 767px) {
    padding: 1em;
    margin-left: auto;
    margin-right: auto; }
  th {
    padding-left: 5px;
    background: var(--primary-color);
    color: var(--light-gray);
    font-weight: normal;
    min-width: 2em;
    @media (max-width: 767px) {
      min-width: 2em; } }

  td {
    border: none;
    padding: 10px;
    min-width: 4em;
    max-width: 20em;
    @media (max-width: 767px) {
      min-width: 4em;
      max-width: 16em; } }

  tr {
    text-align: left; }

  tr:hover {
    background: var(--deep-blue-light); } }

.loadMore {
  margin-left: auto;
  margin-right: auto;
  width: 10em;
  @media (max-width: 767px) {
    margin-left: 0.5em; } }


.paginationContainer {
  display: flex;
  align-items: center;
  padding: 14px 35px 0 35px;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center; } }
