.container {
  padding: 70px;
  min-height: 40vh; }

.content {
  max-width: 1000px;
  margin: 0 auto; }

.otherInfoRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  img {
    max-width: 140px;
    padding-top: 4px;
    margin-left: 2px; } }
