.imgAndButtonContianerIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .imgContainer {
    img {
      width: 100%;
      max-width: 96px;
      max-height: 40px; } }
  .buttonContainer {
    width: 100%;
    max-width: 200px; } }

.imgAndButtonContianerLogo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .imgContainer {
    img {
      width: 100%;
      max-width: 80%;
      max-height: 100%; } }
  .buttonContainer {
    width: 100%;
    max-width: 200px; } }

.imgAndButtonContianerIcon, .imgAndButtonContianerLogo {
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    .buttonContainer {
      padding-left: 0; }

    .imgContainer {
      padding-top: 15px; } } }
