.dataTable {
  padding: 2em 0;
  width: calc(100% - 20px);
  margin: 0 10px;
  @media (max-width: 767px) {
    margin-left: auto;
    margin-right: auto; }
  th {
    padding-left: 5px;
    background: var(--primary-color);
    color: var(--light-gray);
    font-weight: normal;
    min-width: 4em; }
  td {
    border: none;
    padding: 10px; }

  tr {
    text-align: left; }

  tr:hover {
    background: var(--deep-blue-light); } }

.filter {
  width: 45em;
  margin: 1em auto 1em;
  background: var(--deep-blue-light);
  padding: 10px;
  @media (max-width: 767px) {
    margin: 3em 2em -1em;
    width: 26em; } }

.totals {
  margin-top: 0.5em;
  text-align: center; }

.loading {
  position: relative;
  text-align: center;
  min-width: 10em;
  min-height: 2em; }

.tracks {
  max-width: 30em; }

.stage {
  min-width: 6em; }

.odd {
  background: white; }

.even {
  background: var(--light-gray); }

.buttonAction {
  text-align: center; }

.refreshTable {
  margin-left: 1em; }

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em 0;
  width: calc(100% - 20px);
  margin: 0 10px;

  div {
    display: flex;
    align-items: center; }

  @media (max-width: 767px) {
    padding: 14px 14px 0 28px;

    span {
      font-size: 12px; } } }

.btnClose {
  position: absolute;
  top: 0;
  right: 0; }
