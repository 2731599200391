.filter {
  width: 45em;
  margin: 1em auto 1em;
  background: var(--deep-blue-light);
  padding: 10px;
  @media (max-width: 767px) {
    margin: 3em auto -3em;
    width: 26em;
    margin-bottom: 8px; } }

.totals {
  margin-top: 0.5em;
  text-align: center; }

.jumpToContainer {
  text-align: left;
  padding-top: 1em; }

.jumpToForm {
  p, input {
    margin-top: 1em;
    margin-bottom: 1em; }
  input {
    border: 1px solid black;
    padding: 4px;
    max-width: 10em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1; } }

.containerNoResults {
  text-align: center;
  margin-top: 50px; }
