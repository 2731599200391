.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.title {
  font-size: 30px; }

.text {
  font-size: 20px; }
